import React from 'react';
import Header from './Header';

type Props = {
  transparentHeader?: boolean;
}

export default function Root(props: React.PropsWithChildren<Props>) {
  return (
    <>
      <Header transparent={props.transparentHeader} />
      {props.children}
    </>
  );
}
