import { AddUpdateResponse, RemoveResponse } from '@chec/commerce.js/features/cart';
import { Cart } from '@chec/commerce.js/types/cart';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import commerce from '../../lib/commerce'

import {
  RETRIEVE_CART_SUCCESS,
  RETRIEVE_CART_ERROR,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_ERROR,
  UPDATE_CART_ITEM_SUCCESS,
  UPDATE_CART_ITEM_ERROR,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_ERROR
} from './actionTypes';

// Create all Cart actions, define the callbacks to the reducers

/**
 * Set cart and update Redux store
 */
export const retrieveCartSuccess = (cart: Cart) => {
  return {
    type: RETRIEVE_CART_SUCCESS,
    payload: cart
  }
}

/**
 * Handle error on retrieve cart fail
 */
export const retrieveCartError = (error: any) => {
  console.log('Error retrieving cart', error)
  return {
    type: RETRIEVE_CART_ERROR,
  }
}

/**
 * Async retrieve cart from API
 */
export const retrieveCart = () => (dispatch: Dispatch) => {
  return commerce.cart.retrieve()
    .then((cart) => dispatch(retrieveCartSuccess(cart)))
    .catch((error) => dispatch(retrieveCartError(error)));
}


/**
 * Handle add to cart success and update store
 */
export const addToCartSuccess = (product: AddUpdateResponse) => {
  return {
    type: ADD_TO_CART_SUCCESS,
    payload: product
  }
}

/**
 * Handle error on adding product to cart
 */
export const addToCartError = (error: any) => {
  console.log('Error adding product to cart', error);
  return {
    type: ADD_TO_CART_ERROR,
  }
}

/**
 * Async add product to cart
 */
export const addToCart = (productId: string, quantity: number, selectedOption: string) => (dispatch: Dispatch) => commerce.cart.add(productId, quantity, selectedOption)
  .then((product) => dispatch(addToCartSuccess(product)))
  .catch((error) => dispatch(addToCartError(error)));


/**
 * Handle update cart item success and update store
 */
export const updateCartItemSuccess = (item: AddUpdateResponse) => {
  return {
    type: UPDATE_CART_ITEM_SUCCESS,
    payload: item
  }
}

/**
 * Handle error on updating cart item
 */
export const updateCartItemError = (error: any) => {
  console.log('Error updating cart item', error);
  toast(error, { type: 'error' });
  return {
    type: UPDATE_CART_ITEM_ERROR
  }
}

/**
 * Async update cart item
 */
export const updateCartItem = (lineItemId: string, productId: string, quantity: number) => async (dispatch: Dispatch) => {

  if (quantity > 1) {
    var hasStock = await commerce.cart.checkQuantity(productId, quantity);
    if (!hasStock) return dispatch(updateCartItemError("Requested quantity is not available."));
  }

  try {
    const item = await commerce.cart.update(lineItemId, { quantity });
    return dispatch(updateCartItemSuccess(item));
  } catch (error) {
    return dispatch(updateCartItemError(error));
  }
}

/**
 * Handle remove cart item success and update store
 */
export const removeFromCartSuccess = (resp: RemoveResponse) => {
  return {
    type: REMOVE_FROM_CART_SUCCESS,
    payload: resp
  }
}

/**
 * Handle remove cart item error
 */
export const removeFromCartError = (error: any) => {
  console.log('Error removing cart item', error)
  return {
    type: REMOVE_FROM_CART_ERROR
  }
}

/**
 * Async remove cart item
 */
export const removeFromCart = (lineItemId: string) => (dispatch: Dispatch) => commerce.cart.remove(lineItemId)
  .then((resp) => dispatch(removeFromCartSuccess(resp)))
  .catch((error) => dispatch(removeFromCartError(error)));
