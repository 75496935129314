import React, { useEffect } from 'react';

import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../../lotties/add-to-cart.json';

type Props = { 
  isStopped: boolean;
}

const Animation = ({isStopped}: Props) => {
  const playerRef = React.useRef<Player>(null);

  useEffect(() => {
    if (playerRef.current && isStopped) {
      playerRef.current.play();
    }
  }, [isStopped]);

  return (
    <div className="cart-animation">
      <Player
        ref={playerRef}
        keepLastFrame
        src={animationData}
        style={{ height: 32, width: 32 }}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
      />
    </div>
  );
}

export default Animation;
